<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0972 14.0965C14.1397 14.0539 14.1746 14.0037 14.1997 13.948L17.0866 7.53565C17.2762 7.11439 16.842 6.68328 16.4221 6.87595L10.0478 9.80082C9.99356 9.82572 9.94464 9.85994 9.90304 9.90148C9.86118 9.94345 9.82656 9.99263 9.80164 10.0475L6.89543 16.4411C6.70425 16.8617 7.13692 17.2944 7.55751 17.1032L13.9512 14.197C14.0058 14.1722 14.0553 14.1381 14.0972 14.0965ZM11.0115 11.0108L9.36445 14.6342L12.9879 12.9872L11.0115 11.0108Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 12.0001C2 6.47721 6.47715 2.00003 12 2C17.5229 2 22.0002 6.47719 22.0002 12.0001C22.0002 17.523 17.523 22.0002 12.0001 22.0002C6.47719 22.0002 2 17.523 2 12.0001ZM19.9383 11H19C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13H19.9383C19.4871 16.6188 16.6188 19.4871 13 19.9383V19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19V19.9383C7.38125 19.487 4.5131 16.6188 4.06188 13H5C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11H4.0619C4.51318 7.3813 7.3813 4.51318 11 4.0619V5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5V4.06188C16.6188 4.5131 19.487 7.38125 19.9383 11Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
